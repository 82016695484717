$(document).ready(function(){

    var login = sessionStorage.getItem("userName");
    if (login) {
        setNameUser(login);
    }

    jQuery("[data-login-btn]").click(function() {
        var login = jQuery("#modal-login").val();
        var pass = jQuery("#modal-pass").val();
        if((login.length > 3) && (pass.length > 3)) {

            setNameUser(login);
            sessionStorage.setItem('userName', login);
        }
        jQuery("#modal-login").val('');
        jQuery("#modal-pass").val('');
    });

    // for temporary redirecting
    jQuery("[data-auth]").click(function (e) {
        e.preventDefault();
        window.location.href = "https://school.avalon-systems.com/";
    });
    //--------------------------------

    function setNameUser(login) {
        jQuery("[data-user-logged]").removeClass("hidden-block");
        jQuery("[data-slash]").removeClass("hidden-block");
        jQuery("[data-user-unknown]").addClass("hidden-block");
        jQuery("[data-user-name]").removeClass("hidden-block").text(login);
        jQuery("[data-auth-stat]").text("Выйти");
        jQuery("[data-stat-wrap]").addClass("margin-null");
        jQuery("[data-auth]").attr("data-target", "");

        jQuery("[data-auth-stat]").click(function (e) {

            e.preventDefault();
            if(jQuery(e.target) == jQuery(this)) {

            }
            e.preventDefault();

            jQuery("[data-user-logged]").addClass("hidden-block");
            jQuery("[data-slash]").addClass("hidden-block");
            jQuery("[data-user-unknown]").removeClass("hidden-block");
            jQuery("[data-user-name]").addClass("hidden-block");
            jQuery("[data-auth-stat]").text("Войти");
            jQuery("[data-stat-wrap]").removeClass("margin-null");

            sessionStorage.removeItem("userName");

            jQuery("[data-auth]").attr("data-target", "#auth-modal");
            jQuery("[data-auth]").modal("hide");

        });
    }

    jQuery("[data-close-mob]").click(function(){
        jQuery(".navigation").hide();
    });
    jQuery("[data-burger-menu]").click(function () {
        jQuery(".navigation").show();
    })

});