jQuery(document).ready(function(){

    var inputText = jQuery("input[type=text]");
    inputText.keyup(function () {
        if (jQuery(this).val() != "") {
            jQuery(this).addClass("full-text");
        } else {
            jQuery(this).removeClass("full-text");
        }
    });

    inputText.change(function () {
        if (jQuery(this).val() != "") {
            jQuery(this).addClass("full-text");
        } else {
            jQuery(this).removeClass("full-text");
        }
    });

    if (jQuery("[name=phone]").length > 0) {
        jQuery("[name=phone]").mask("+7 (999) 999-99-99");
    }
});