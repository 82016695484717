jQuery(document).ready(function(){

    if (jQuery("#rate-content-school").length > 0) {
        jQuery("#rate-content-school").owlCarousel({
            items: 1,
            nav: true,
            navText: ["", ""],
            loop: true,
            responsive : {
                0 : {
                    nav: true
                },
                // breakpoint from "x" to up
                992: {
                    items: 3,
                },
                768: {
                    items: 2,
                },
                576: {
                    nav: true,
                }
            }
        });
    }


    jQuery("[data-tab]").on("click", function (e) {

        var target = jQuery(e.target).closest("[data-tab]");
        var index = target.attr("data-tab");
        if (target.hasClass("active")) {
            return;
        }
        jQuery("[data-tab-item]").addClass("hidden-block");
        jQuery(".tab.active").removeClass("active");
        target.addClass("active");
        switch (index) {
            case "1":
                jQuery("#rate-content-school").removeClass("hidden-block");
                jQuery("#rate-school-desc").removeClass("hidden-block");
                break;
            case "2":
                jQuery("#rate-content-food").removeClass("hidden-block");
                break;
        }
    })

});