jQuery(document).ready(function(){

    if (jQuery(".slider_ipad").length > 0) {
        jQuery(".slider_ipad").owlCarousel({
            items: 1,
            nav: true,
            navText: ["", ""],
            loop: true,
            responsive : {
                0 : {
                    nav: true
                },
                // breakpoint from 768 up
                768: {
                }
            }
        });
    }

    jQuery('[data-trigger]').on("click", function (e) {
        var li = jQuery(this).closest(".advantage-mob-item");
        if (li.hasClass("active")) {
            li.removeClass("active");
            return;
        }
        jQuery(".advantage-mob-item").removeClass("active");
        li.addClass("active");
    });

    $("[data-modal-school]").click(function () {
        var target = jQuery(this).attr("data-target");
        jQuery(target).modal("show");
    });

    function hasTouch() {
        return 'ontouchstart' in document.documentElement
            || navigator.maxTouchPoints > 0
            || navigator.msMaxTouchPoints > 0;
    }

    if (hasTouch()) { // remove all :hover stylesheets
        try { // prevent exception on browsers not supporting DOM styleSheets properly
            for (var si in document.styleSheets) {
                var styleSheet = document.styleSheets[si];
                if (!styleSheet.rules) continue;

                for (var ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
                    if (!styleSheet.rules[ri].selectorText) continue;

                    if (styleSheet.rules[ri].selectorText.match(':hover')) {
                        styleSheet.deleteRule(ri);
                    }
                }
            }
        } catch (ex) {}
    }
});