$(document).on('click', '[data-send-btn]', function (e) {
    e.preventDefault();
    $.validator.setDefaults({
        debug: true,
        success: "valid"
    });
    $( ".back-form-header" ).validate({
        rules: {
            name: {
                required: true,
                minlength: 2
            },
            phone: {

            },
            message: {
                required: true,
                minlength: 2
            },
            email: {
                required: true,
                email: true
            }
        },
        messages: {
            name: {
                required: "Поле обязательное для заполнения",
                minlength: "Минимальное значение 2 символа"
            },
            phone: {
                required: "Поле обязательное для заполнения",
            },
            message: {
                required: "Поле обязательное для заполнения",
                minlength: "Минимальное значение 2 символа"
            },
            email: {
                required: "Поле обязательное для заполнения",
                email: "Формат должен быть: example@mail.com"
            }
        }
    });
    if ($(".back-form-header").valid() == false) {
        console.log('error');
    } else {
        $('[data-send-btn]').prop('disabled', true);
        $('[data-send-btn]').text('отправляю');
        var url = 'mail/controller/save_form.php';
        var data = $('.back-form-header').serialize();
        $.post(url, data, function( data ) {
            /**
             * 200 если успешно отправило
             * 450 если пустой логин или пароль пришел
             * 451 если ошибка во время отправки
             */
        });
        function addPreloaderToBtn() {
            $('[data-send-btn]').prop('disabled', false);
            $('[data-send-btn]').text('жду ответ');
            $(".back-form-header")[0].reset();
            function closemodal(){
                $('#send-mess').modal('hide')
            }
            setTimeout(closemodal, 1500);
        }
        setTimeout(addPreloaderToBtn, 3000);
        function goodSend() {
            $('[data-send-btn]').text('Отправлено');
        }
        setTimeout(goodSend, 3050);
    }
});

$(document).on('click', '.submit_form1', function (e) {
    e.preventDefault();
    $.validator.setDefaults({
        debug: true,
        success: "valid"
    });
    $( ".back-form" ).validate({
        rules: {
            name: {
                required: true,
                minlength: 2
            },
            phone: {

            },
            message: {
                required: true,
                minlength: 2
            },
            email: {
                required: true,
                email: true
            }
        },
        messages: {
            name: {
                required: "Поле обязательное для заполнения",
                minlength: "Минимальное значение 2 символа"
            },
            phone: {
                required: "Поле обязательное для заполнения",
                minlength: "Минимальное значение 2 символа"
            },
            message: {
                required: "Поле обязательное для заполнения",

                minlength: "Минимальное значение 2 символа"
            },
            email: {
                required: "Поле обязательное для заполнения",
                email: "Формат должен быть: example@mail.com"
            }
        }
    });
    if ($(".back-form").valid() == false) {
        console.log('error');
    } else {
        $('.submit_form1').prop('disabled', true);
        $('.submit_form1').text('отправляю');
        var url = 'mail/controller/save_form.php';
        var data = $('.back-form').serialize();
        $.post(url, data, function( data ) {
            /**
             * 200 если успешно отправило
             * 450 если пустой логин или пароль пришел
             * 451 если ошибка во время отправки
             */
        });
        function addPreloaderToBtn() {
            $('.submit_form1').prop('disabled', false);
            $("form")[0].reset();
            $('.submit_form1').text('жду ответ');
        }
        setTimeout(addPreloaderToBtn, 3000);
        function goodSend() {
            $('.submit_form1').text('Отправлено');
        }
        setTimeout(goodSend, 3050);
    }
});